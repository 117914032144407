import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { services } from 'src/app/services/services.service';
@Component({
    selector: 'app-coming-soon-page',
    templateUrl: './coming-soon-page.component.html',
    styleUrls: ['./coming-soon-page.component.scss']
})
export class ComingSoonPageComponent {

    constructor(private activeRoute: ActivatedRoute, private services: services) { }

    ngOnInit(): void {

    }


}