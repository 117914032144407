import { Injectable } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OnlineDonate } from '../models/onlineDonate';

@Injectable({
    providedIn: 'root'
})
export class StripeService {
    private stripePromise: Promise<Stripe | null>;

    constructor() {
        this.stripePromise = loadStripe(environment.stripe.testPublishableKey);
    }

    getStripe() {
        return this.stripePromise;
    }
}