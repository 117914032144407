import { Component, ElementRef, OnInit } from '@angular/core';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import lgVideo from 'lightgallery/plugins/video';
import { environment } from 'src/environments/environment';
import { services } from '../../../services/services.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { prayerTime } from 'src/app/models/prayerTime';
import { PrayerTimeService } from '../../../services/prayerTime.service';
import { SmallItemService } from '../../../services/smallItem.service';
import { SmallSlider } from 'src/app/models/smallSlider';


@Component({
  selector: 'app-home-page-eight',
  templateUrl: './home-page-eight.component.html',
  styleUrls: ['./home-page-eight.component.scss']
})
export class HomePageEightComponent implements OnInit {
  list: any[] = [];
  photoUrl: string = environment.serverUrl
  smallSlider: SmallSlider;
  prayerTime: prayerTime;
  selectedCity: any;
  selectedCountry: any;
  country: any;
  cities: any;
  currentYear = new Date().getFullYear();
  specialDays: any

  constructor(private prayerTimeService: PrayerTimeService, private smallItemService: SmallItemService) { }

  ngOnInit(): void {
    this.getCityByLangCode();
    this.getSmallSlider();
  }

  getPrayerTime(long, lat) {
    this.prayerTimeService.getPrayerTimes(long, lat).subscribe((resp: any) => {
      this.prayerTime = resp.daily.data[0];
    })
  }

  getCityByLangCode() {
    this.prayerTimeService.getFrontCountryByLangcode('CH').subscribe((data: any) => {
      this.cities = data[0].cities;
      this.getPrayerTime(this.cities[0].longitude, this.cities[0].latitude)
    })
  }

  onCitySelected(x: any) {
    this.selectedCity = x.target.value
    this.prayerTimeService.getFrontCityById(this.selectedCity).subscribe((data: any) => {
      this.getPrayerTime(data.longitude, data.latitude);
    })
  }

  getSmallSlider() {
    this.smallItemService.getAllSmallSlider().subscribe((data: any) => {
      this.smallSlider = data.data.filter(slider => slider.isActive === true && slider.isDeleted === false).sort((a, b) => a.order - b.order);

    })
  }


  isIconOpen = false;

  toggleIcons() {
    this.isIconOpen = !this.isIconOpen;
  }

  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
    event.preventDefault();
    this.currentTab = tab;
  }

  //TAB DUYURULAR

  settings = {
    counter: false,
    plugins: [lgVideo]
  };
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
  };

  blogSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 30,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      1200: {
        items: 3
      }
    }
  }

  classApplied2 = false;
  toggleClass2() {
    this.classApplied2 = !this.classApplied2;
  }

  selectedItem = null;

  toggleAccordionItem(list) {
    list.open = !list.open;
    if (this.selectedItem && this.selectedItem !== list) {
      this.selectedItem.open = false;
    }
    this.selectedItem = list;
  }

}
