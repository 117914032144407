import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Content } from 'src/app/models/content';
import { Country } from 'src/app/models/country';
import { UmrahApplication } from 'src/app/models/umrahApplication';
import { ApplicationService } from 'src/app/services/application.service';
import { ContentService } from 'src/app/services/content.service';
import { PrayerTimeService } from 'src/app/services/prayerTime.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UmrahService } from 'src/app/services/umrah.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { MaritalStatus } from './../../../../models/maritalStatus';
import { AppSettingService } from 'src/app/services/appsetting.service';
import { LanguageService } from 'src/app/services/language.service';
import { RoomAndPeriod } from 'src/app/models/roomType';
@Component({
  selector: 'app-umrah',
  templateUrl: './umrah.component.html',
  styleUrls: ['./umrah.component.scss']
})
export class UmrahComponent implements OnInit {
  isLoading: boolean = false;
  personalInformationForm: UntypedFormGroup;
  passportForm: UntypedFormGroup;
  otherForm: UntypedFormGroup;
  roomTypes: any[] = [];
  application: UmrahApplication[] = [];
  isValid: boolean = false;
  isRegulation: boolean = false;
  periods: any[] = [];
  photoUrl: string = environment.serverUrl
  gender: any[] = [];
  guidanceLanguage: any[] = [];
  selectedFormLang: any;
  roomMatesNumber: any;
  maritalStatus: MaritalStatus[] = [];
  countries: Country[] = [];
  regulation: Content;
  date = new Date();
  periodSelected = false;
  ageLimit: string = null;
  info: string[] = [];

  constructor(private fb: FormBuilder, private applicationService: ApplicationService, private umrahService: UmrahService, private contentService: ContentService, private translationService: TranslationService, private prayerTimeService: PrayerTimeService, private appSettingService: AppSettingService, private languageService: LanguageService) { }

  ngOnInit(): void {
    this.createForm();
    this.selectedFormLang = localStorage.getItem('language');
    this.getServices();
    this.getGuidanceLanguage();


  }


  async getServices() {
    const services = [
      this.contentService.getRegulation(),
      this.applicationService.getGender(),
      this.applicationService.getMaritalStatus(),
      this.umrahService.getUmrahPeriods(),
      this.prayerTimeService.getFrontAllCountry(),
    ]
    forkJoin(services).subscribe(([regulation, gender, maritalStatus, periods, countries]) => {
      this.regulation = regulation?.data?.find((item: any) => item?.title === `umreyonetmelik${this.selectedFormLang}`).folderPath;
      this.gender = gender;
      this.maritalStatus = maritalStatus;
      this.periods = periods.filter(period => period.isActive);
      if (this.periods.length > 0) {
        this.getAgeInfo();
      }
      this.countries = countries.sort((a, b) => a.name.localeCompare(b.name));
    })

  }

  getAgeInfo() {
    this.periods.map((period: any) => {
      if (period.ageLimit) {
        const match = period.ageLimit.match(/^([<>]=?|===|!==)?(\d+)$/);
        if (!match) {
          throw new Error(`Invalid age limit format: ${period.ageLimit}`);
        } else {
          const limit = Number(match[2]);
          
          let ageText;
          if (this.selectedFormLang === 'tr') {
            ageText = {
              '<': `Bu tura katılabilmek için ${limit} yaşından küçük olmanız gerekir.`,
              '>': `Bu tura katılabilmek için ${limit} yaşından büyük olmanız gerekir.`,
              '>=': `Bu tura katılabilmek için yaşınızın ${limit} veya daha büyük olması gerekir.`,
              '<=': `Bu tura katılabilmek için yaşınızın ${limit} veya daha küçük olması gerekir.`,
              '===': `Bu tura katılabilmek için ${limit} yaşında olmanız gerekir.`,
              '!==': `Bu tura katılabilmek için ${limit} yaşında olmamanız gerekir.`,
            };
          } else {
            ageText = {
              '<': `Sie müssen jünger als ${limit} Jahre alt sein.`,
              '>': `Sie müssen älter als ${limit} Jahre alt sein.`,
              '>=': `Sie müssen ${limit} Jahre alt oder älter sein.`,
              '<=': `Sie müssen ${limit} Jahre alt oder jünger sein.`,
              '===': `Sie müssen ${limit} Jahre alt sein.`,
              '!==': `${limit} Jährige dürfen nicht an die ausgewählte Tour teilnehmen.`
            };
          }


          const text = ageText[match[1] || '==='];

          if (text) {
            this.info.push(`${period.name} - ${text}`);
          } else {
            throw new Error('Bilinmeyen bir hata oluştu.');
          }
        }
      }
    })
  }

  getRoomTypes(e) {
    this.applicationService.getRoomAndPeriod().subscribe((data: any) => {

      let period = data.filter(item => item.periodId == e.target.value)

      const formValue = this.otherForm.get('roomTypeId');

      this.periodSelected = !!this.otherForm.get('periodId')?.value;
      formValue.setValue('');

      if (period.length > 0 && period !== undefined) {
        if (period[0].ageLimit) {
          this.ageLimit = period[0].ageLimit
          this.checkAgeLimit();
        }

        this.roomTypes = period[0].rooms
        this.roomMates.clear();

        if (this.otherForm.get('periodId')?.invalid && (this.otherForm.get('periodId').dirty || this.otherForm.get('periodId').touched)) {
          formValue.disable();

        } else {
          formValue.enable();

        }
      } else {
        formValue.disable();

      }

      return this.roomTypes


    })
  }

  checkAgeLimit() {
    let birthday = new Date(this.personalInformationForm.get('birthDay').value);
    let today = new Date();

    let ageYears = today.getFullYear() - birthday.getFullYear();
    let ageMonths = today.getMonth() - birthday.getMonth();
    let ageDays = today.getDate() - birthday.getDate();

    if (ageDays < 0) {
      ageMonths--;
      ageDays += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    if (ageMonths < 0) {
      ageYears--;
      ageMonths += 12;
    }


    const match = this.ageLimit.match(/^([<>]=?|===|!==)?(\d+)$/);
    if (!match) {
      throw new Error(`Invalid age limit format: ${this.ageLimit}`);
    } else {
      const limit = Number(match[2]);
      let isValid = false;
      let errorMessage = '';

      switch (match[1] || '===') {
        case '>':
          isValid = ageYears > limit;
          errorMessage = this.selectedFormLang === 'tr' ? `Bu tura katılabilmek için ${limit} yaşından büyük olmanız gerekir.` : `Sie müssen älter als ${limit} Jahre alt sein.`;
          break;
        case '<':
          isValid = ageYears < limit;
          errorMessage = this.selectedFormLang === 'tr' ? `Bu tura katılabilmek için ${limit} yaşından küçük olmanız gerekir.` : `Sie müssen jünger als ${limit} Jahre alt sein.`;
          break;
        case '>=':
          isValid = ageYears >= limit;
          errorMessage = this.selectedFormLang === 'tr' ? `Bu tura katılabilmek için yaşınızın ${limit} veya daha büyük olması gerekir.` : `Sie müssen ${limit} Jahre alt oder älter sein.`;
          break;
        case '<=':
          isValid = ageYears <= limit;
          errorMessage = this.selectedFormLang === 'tr' ? `Bu tura katılabilmek için yaşınızın ${limit} veya daha küçük olması gerekir.` : `Sie müssen ${limit} Jahre alt oder jünger sein.`;
          break;
        case '===':
          isValid = ageYears === limit;
          errorMessage = this.selectedFormLang === 'tr' ? `Sie müssen ${limit} Jahre alt sein.` : `Sie müssen ${limit} Jahre alt sein.`;
          break;
        case '!==':
          isValid = ageYears !== limit;
          errorMessage = this.selectedFormLang === 'tr' ? `${limit} Jährige dürfen nicht an die ausgewählte Tour teilnehmen.` : `${limit} Jährige dürfen nicht an die ausgewählte Tour teilnehmen.`;
          break;
        default:
          throw new Error(`Bilinmeyen bir hata oluştu.`);
      }

      if (!isValid) {
        return errorMessage;
      }

      return true;
    }

  }

  getGuidanceLanguage() {
    let languagesList: any[] = [];

    const services = [
      this.appSettingService.getAppSetting(),
      this.languageService.getAllLanguage()

    ]
    forkJoin(services).subscribe(([appSettingData, allLanguageData]) => {
      languagesList = appSettingData.find(item => item.key == "umrerehberlikdilleri")?.value.split(',').map(Number);

      this.guidanceLanguage = allLanguageData
        .filter(language => languagesList.includes(language.id));

    })
  }

  checkYes(e) {
    this.isRegulation = e.checked;
  }

  checkForm(form: AbstractControl): boolean {
    this.isValid = form.dirty && form.valid && form.touched;
    return this.isValid;
  }


  nextTab(currentTabIndex: number) {
    currentTabIndex++;
    const tabId = `tab${currentTabIndex + 1}`;
    const tabRadio = document.getElementById(tabId) as HTMLInputElement;
    if (tabRadio) {
      tabRadio.click();
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    }

  }


  createForm() {
    this.personalInformationForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      fatherName: ['', [Validators.required]],
      motherName: ['', [Validators.required]],
      identificationNumber: ['', [Validators.minLength(11)]],
      genderId: ['', [Validators.required]],
      birthDay: ['', [Validators.required]],
      birthPlace: [''],
      maritalStatusId: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      homePhone: [''],
      email: ['', [Validators.required, Validators.email]],
      address: ['', [Validators.required]],
      postCode: ['', [Validators.required]],
      city: ['', [Validators.required]],
    })


    this.otherForm = this.fb.group({
      roomTypeId: [{ value: '', disabled: true }, Validators.required],
      periodId: ['', Validators.required],
      roomMates: this.fb.array([this.createRoomMatesForm()]),
      guidanceLanguageId: ['', Validators.required]

    })

    this.passportForm = this.fb.group({
      passportNumber: ['', [Validators.required]],
      passportGivenDate: ['', [Validators.required]],
      passportValidityDate: ['', [Validators.required]],
      passportCountryName: ['', [Validators.required]],
    })


  }

  createRoomMatesForm() {
    return this.fb.group({
      firstName: [''],
      lastName: ['']
    })
  }

  get roomMates() {
    return this.otherForm.get('roomMates') as FormArray
  }

  addNewRoomMates(e) {
    const selectedValue = parseInt(e.target.value, 10);

    const boxesToShow = selectedValue - 1;

    while (this.roomMates.length < boxesToShow) {
      this.roomMates.push(this.createRoomMatesForm());
    }

    while (this.roomMates.length > boxesToShow) {
      this.roomMates.removeAt(this.roomMates.length - 1);
    }

    this.roomMatesNumber = boxesToShow;
  }


  checkpassportValidityDate() {
    const passportExpiration = this.passportForm?.get('passportValidityDate').value;

    if (passportExpiration) {
      const passportValidityDate = new Date(passportExpiration);
      const formatpasportexpiration = formatDate(passportValidityDate, 'dd-MM-yyyy', 'en-US');
      const currentDate = new Date(); //şimdiki zaman
      const formatcurrentDate = formatDate(currentDate, 'dd-MM-yyyy', 'en-US');

      const eightMonthsFromNow = new Date();
      eightMonthsFromNow.setMonth(currentDate.getMonth() + 6);
      eightMonthsFromNow.setDate(currentDate.getDate() - 1);
      const formateightmonthsfromnow = formatDate(eightMonthsFromNow, 'dd-MM-yyyy', 'en-US');

      if (currentDate >= passportValidityDate) {
        this.passportForm.get('passportValidityDate').setErrors({ expired: true });

      }
      else if (eightMonthsFromNow >= passportValidityDate) {
        this.passportForm.get('passportValidityDate').setErrors({ tooSoon: true });

      }

    }
  }


  createMainBuildObject(): any {
    const application: UmrahApplication = {
      languageCode: this.selectedFormLang,
      firstName: this.personalInformationForm.get('firstName').value,
      lastName: this.personalInformationForm.get('lastName').value,
      fatherName: this.personalInformationForm.get('fatherName').value,
      motherName: this.personalInformationForm.get('motherName').value,
      identificationNumber: this.personalInformationForm.get('identificationNumber').value,
      genderId: this.personalInformationForm.get('genderId').value,
      birthDay: this.personalInformationForm.get('birthDay').value,
      birthPlace: this.personalInformationForm.get('birthPlace').value,
      phone: this.personalInformationForm.get('phone').value,
      email: this.personalInformationForm.get('email').value,
      address: this.personalInformationForm.get('address').value,
      postCode: this.personalInformationForm.get('postCode').value,
      city: this.personalInformationForm.get('city').value,
      maritalStatusId: this.personalInformationForm.get('maritalStatusId').value,
      homePhone: this.personalInformationForm.get('homePhone').value,


      passportNumber: this.passportForm.get('passportNumber').value.toUpperCase(),
      passportGivenDate: this.passportForm.get('passportGivenDate').value,
      passportValidityDate: this.passportForm.get('passportValidityDate').value,
      passportCountryName: this.selectedFormLang === 'tr' ? this.passportForm.get('passportCountryName').value : this.translationService.getValue(this.passportForm.get('passportCountryName').value),

      roomTypeId: Number(this.otherForm.get('roomTypeId').value),
      periodId: Number(this.otherForm.get('periodId').value),
      guidanceLanguageId: this.otherForm.get('guidanceLanguageId').value,

      roomMates: this.roomMates.value
    };

    return application;
  }



  saveForm() {
    this.isLoading = true;

    const valid = this.checkAgeLimit();
    if (valid != true) {
      this.application = this.createMainBuildObject();

      Swal.fire({
        icon: 'info',
        text: valid,
        confirmButtonText: this.translationService.getValue('CLOSE'),
      });
      this.isLoading = false;
      return;
    }

    if (this.personalInformationForm.valid && this.otherForm.valid && valid) {
      this.application = this.createMainBuildObject();

      this.umrahService.addUmreForm(this.application).subscribe((data: any) => {
        this.isLoading = false;


        Swal.fire({
          icon: 'success',
          title: this.translationService.getValue('SUCCESS'),
          text: this.translationService.getValue('IHRE_ANMELDUNG_WURDE_ERFOLGREICH_GESENDET'),
          confirmButtonText: this.translationService.getValue('CLOSE'),

        })
          .then((success) => {
            // this.router.navigateByUrl('/hac-bilgi/' + this.hajjDetail)
            window.location.reload();
          });
      }, (error: any) => {

        this.isLoading = false;
        {
          Swal.fire({
            icon: 'error',
            title: this.translationService.getValue('UNSUCCESS'),
            text: error.error[0],
            confirmButtonText: this.translationService.getValue('CLOSE'),
          }
          )
        }
      }
      )
    }
    else {
      this.isLoading = false;
      Swal.fire({
        icon: 'error',
        title: this.translationService.getValue('UNSUCCESS'),
        text: this.translationService.getValue('BEI_DER_BEWERBUNG_IST_EIN_FEHLER_AUFGETRETEN'),
        confirmButtonText: this.translationService.getValue('CLOSE')
      }
      )
    }
  }

}
