import { Component, OnInit } from '@angular/core';
import { AppSettingService } from 'src/app/services/appsetting.service';
import { FooterService } from 'src/app/services/footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  address: any;
  telephone: any;
  facebook: any;
  twitter: any;
  instagram: any;
  youtube: any;
  hour: any;
  mail: any;

  footerArea: any;


  constructor(private footerService: FooterService, private appSettingService: AppSettingService) { }

  ngOnInit(): void {
    this.getAppSettings();
    this.getFooterMenus();
  }


  getFooterMenus() {
    this.footerService.getFooter().subscribe((data: any) => {
      this.footerArea = data.data
        .filter(item => item.isActive)
        .map(item => {
          item.footerItems = item.footerItems
            .filter((footerItem: any) => footerItem.isActive)
            .sort((a: any, b: any) => a.order - b.order);
          return item;
        });
    });
  }




  getAppSettings() {
    this.appSettingService.getAppSetting().subscribe((data: any) => {
      this.address = data.find(item => item.key === "adres")?.value;
      this.telephone = data.find(item => item.key === "telefon")?.value;
      this.facebook = data.find(item => item.key === "facebook")?.value;
      this.twitter = data.find(item => item.key === "twitter")?.value;
      this.instagram = data.find(item => item.key === "instagram")?.value;
      this.youtube = data.find(item => item.key === "youtube")?.value;
      this.hour = data.find(item => item.key === "calismasaati")?.value;
      this.mail = data.find(item => item.key === "mail")?.value;
    })
  }



}
