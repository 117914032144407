<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a href="/">Anasayfa</a></li>
                <li>İletişim</li>
            </ul> -->
            <h2>{{'COMMUNICATION'|translate}}</h2>
        </div>
    </div>
</div>

<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>{{'EMAIL'|translate}}</h3>
                    <p><a href="mailto:{{mail}}">{{ mail }}</a></p>

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>{{'ADDRESS'|translate}}</h3>
                    <p><a rel="noopener" href="https://www.google.com/maps/place/{{ address }}" target="_blank">{{
                            address }}</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>{{'TELEPHONE'|translate}}</h3>
                    <p><a href="tel:{{ telephone }}">{{ telephone }}</a></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <h2 style="margin-top: 10px;">{{'CONTACT_US'|translate}}</h2>
        </div>
        <div class="contact-form">
            <form id="contactForm" [formGroup]="form" (ngSubmit)="submitForm()">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" name="firstName" formControlName="firstName"
                                id="name" type="text" class="form-control" placeholder="{{'NAME'|translate}}">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" name="lastName" formControlName="lastName"
                                id="lastName" class="form-control" placeholder="{{'SURNAME'|translate}}">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required id="email" name="mail" formControlName="mail" type="text"
                                class="form-control" placeholder="{{'EMAIL'|translate}}">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required name="title" id="title" formControlName="title" class="form-control"
                                placeholder="{{'SUBJECT'|translate}}">
                        </div>
                    </div>
                    <!-- <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input required name="subject" formControlName="subject" id="name" class="form-control"  placeholder="Konunuz">
                        </div>
                    </div> -->
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea required name="message" id="message" formControlName="message" cols="30" rows="5"
                                class="form-control" placeholder="{{'MESSAGE'|translate}}"></textarea>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn"><i
                                class='bx bx-paper-plane icon-arrow before'></i><span class="label">{{'SUBMIT'|translate}}</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image"><img src="assets/img/isvicrePhotos/map.png" alt="image"></div>
</div>

<div class="container">
    <div class="row justify-content-center align-items-center">
        <div class="col-md-8">
            <div id="map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2697.3727902414976!2d8.523195676901949!3d47.46316447117701!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479075216e73bd41%3A0x63afa0cc4cc4dbbd!2sOberglatterstrasse%2035!5e0!3m2!1str!2str!4v1709626110153!5m2!1str!2str"
                    width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>


        <div class="col-md-4 mt-50">
            <div class="sketch">
                <img src="assets/img/isvicrePhotos/Kroki.png" alt="">
            </div>
        </div>
    </div>
</div>