


<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a href="/">Anasayfa</a></li>
                <li>Personel</li>
            </ul> -->
            <h2>{{'EMPLOYEE'|translate}}</h2>
        </div>
    </div>
</div>

<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" *ngFor="let item of list">
                <div class="single-team-card mb-30">
                    <img  *ngIf="item?.image" src={{photoUrl+item?.image}} alt="image">
                    <img  *ngIf="!item?.image" src="assets/img/isvicrePhotos/logo/itdvlogo.svg" alt="image">
                    <div class="team-content">
                        <h3>{{item?.name}} {{item?.surname}}</h3>
                        <span>{{item?.jobDescription}}</span>
                        <div class="social-btn">
                            <span title="{{item.email1}}" *ngIf="item.email1"><a href="mailto:{{item?.email1}}"><i class="fa-regular fa-envelope"></i></a></span>
                            <span title="{{item.email2}}" *ngIf="item.email2"><a href="mailto:{{item?.email2}}"><i class="fa-regular fa-envelope"></i></a></span>
                            <span title="{{item.phone}}" *ngIf="item.phone"><a href="mailto:{{item?.phone}}"><i class="fa-regular fa-phone"></i></a></span>
                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<!-- <app-become-instructor-partner></app-become-instructor-partner> -->