import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { ApplicationService } from 'src/app/services/application.service';
import { AppSettingService } from 'src/app/services/appsetting.service';
import { ContentService } from 'src/app/services/content.service';
import { LanguageService } from 'src/app/services/language.service';
import { PrayerTimeService } from 'src/app/services/prayerTime.service';

import { CheckBoxOption } from 'src/app/models/checkboxOption';
import { Content } from 'src/app/models/content';
import { Country } from 'src/app/models/country';
import { DynamicValue } from 'src/app/models/dynamicValue';
import { Gender } from 'src/app/models/gender';
import { MaritalStatus } from 'src/app/models/maritalStatus';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { TranslationService } from 'src/app/services/translation.service';
import { Language } from 'src/app/models/language';
import { TranslateService } from '@ngx-translate/core';
import { services } from 'src/app/services/services.service';

@Component({
    selector: 'app-funeral-fund',
    templateUrl: './funeral-fund.html',
    styleUrls: ['./funeral-fund.css']
})
export class FuneralFundComponent implements OnInit {
    isLoading = false;
    mainForm: UntypedFormGroup
    spouseForm: UntypedFormGroup
    turkeyAddressForm: UntypedFormGroup
    switzerlandAddressForm: UntypedFormGroup
    memberForm: UntypedFormGroup
    countries: Country[] = []
    gender: Gender[] = [];
    haveDualNationality: boolean = false;
    haveSpouseDualNationality: boolean = false;
    haveAnotherFund: boolean = false;
    showSpouseForm: boolean = false
    showMemberForm: boolean = false;
    selectedFormLang: any;
    married: boolean = false;
    isValid: boolean = false;
    isRegulation: boolean = false;
    cities: Country[] = [];
    citizenShipCountry: Country[] = [];
    correspondenceLanguage: DynamicValue[] = [];
    formLanguage: Language[] = []
    photoUrl: string = environment.serverUrl
    checkBoxOption = new CheckBoxOption().checkBoxArray;
    maritalStatus: MaritalStatus[] = [];
    regulation: Content;
    residencePermit: DynamicValue[] = [];



    constructor(private services: services,
        private languageService: LanguageService,
        private formBuilder: FormBuilder,
        private applicationService: ApplicationService,
        private prayerTimeService: PrayerTimeService,
        private contentService: ContentService,
        private appSettingService: AppSettingService,
        private translate: TranslateService,
        private translationService: TranslationService
    ) {

    }

    ngOnInit(): void {
        this.getRegulation();
        this.createAddressForm();
        this.createMainForm();
        this.createSpouseForm()
        this.addFamilyMember();
        this.getGender();
        this.getTurkishCities();
        this.getMaritalStatus();
        this.getAppSettingValues();
        this.getDynamicValues();
        this.selectedFormLang = localStorage.getItem('language');

    }


    changeFormLang(e) {
        this.selectedFormLang = e.target.value
        this.translate.use(this.selectedFormLang);
        this.getRegulation();
    }

    getRegulation() {
        this.contentService.getRegulation().subscribe((data: any) => {
            this.regulation = data?.data?.find((item: any) => item?.title === `cenazefonuyonetmelik${this.selectedFormLang}`).folderPath;
        })
    }

    getTurkishCities() {
        this.prayerTimeService.getCountryCitiesById(1).subscribe((data: any) => {
            this.cities = data[0].cities
        })
    }


    getAppSettingValues() {
        let languagesList: any[] = [];
        let correspondenceLanguage: any[] = [];
        let countryList: any[] = [];


        const services = [
            this.appSettingService.getAppSetting(),
            this.languageService.getAllLanguage(),
            this.prayerTimeService.getFrontAllCountry()
        ]
        forkJoin(services).subscribe(([appSettingData, allLanguageData, allCountryData]) => {
            languagesList = appSettingData.find(item => item.key == "formdillercf")?.value.split(',').map(Number);
            correspondenceLanguage = appSettingData.find(item => item.key == "cenazefonuyazismadilleri")?.value.split(',').map(Number);
            countryList = appSettingData.find(item => item.key == "vatandaslikulkeler")?.value.split(',').map(Number);


            this.formLanguage = allLanguageData
                .filter(language => languagesList.includes(language.id)).sort((a, b) => a.id - b.id);

            this.correspondenceLanguage = allLanguageData
                .filter(language => correspondenceLanguage.includes(language.id)).sort((a, b) => a.id - b.id);


            this.countries = allCountryData.sort((a, b) => a.name.localeCompare(b.name));

            countryList.map(countryId => {
                this.citizenShipCountry.push(this.countries.find(country => country?.id === countryId));
            });

        })

    }


    getDynamicValues() {
        this.applicationService.getDynamicValue().subscribe((data: DynamicValue[]) => {
            this.residencePermit = data.filter(value => value.typeId === 4);
        })
    }

    getGender() {
        this.applicationService.getGender().subscribe((data: Gender[]) => {
            this.gender = data;
        });
    }

    getMaritalStatus() {
        this.applicationService.getMaritalStatus().subscribe((data: MaritalStatus[]) => {
            this.maritalStatus = data;
        });
    }


    checkForm(form: AbstractControl): boolean {
        this.isValid = form.dirty && form.valid && form.touched;
        return this.isValid;
    }

    nextTab(currentTabIndex: number) {
        currentTabIndex++;
        const tabId = `tab${currentTabIndex + 1}`;
        const tabRadio = document.getElementById(tabId) as HTMLInputElement;
        if (tabRadio) {
            tabRadio.click();
        }
    }

    spouseFormShow() {
        this.showSpouseForm = !this.showSpouseForm;
    }

    showFamilyMemberForm() {
        this.showMemberForm = !this.showMemberForm;

    }

    createMainForm() {
        this.mainForm = this.formBuilder.group({
            fullName: ['', Validators.required],
            birthPlace: ['', Validators.required],
            birthDate: ['', Validators.required],
            maritalStatusId: ['', Validators.required],
            genderId: ['', Validators.required],
            email: ['', Validators.required],
            haveDualNationality: ['', Validators.required],
            dualNationalityCountryId: [''],
            residencePermit: ['', Validators.required],
            languageId: ['', Validators.required],
            registeredAnotherFund: ['', Validators.required],
            citizenshipId: ['', Validators.required],
            fundPDFPath: [''],
            addresses: this.formBuilder.array([]),
            familyMembers: this.formBuilder.array([]),
        });
    }

    createSpouseForm() {
        this.spouseForm = this.formBuilder.group({
            fullName: ['', Validators.required],
            genderId: ['', Validators.required],
            birthPlace: ['', Validators.required],
            birthDate: ['', Validators.required],
            haveDualNationality: ['', Validators.required],
            dualNationalityCountryId: [''],
            memberTypeId: 2,
            residencePermit: ['', Validators.required],
            citizenshipId: ['', Validators.required],
        });
    }

    createAddressForm() {
        this.switzerlandAddressForm = this.formBuilder.group({
            adres: ['', Validators.required],
            postCode: ['', Validators.required],
            no: ['', Validators.required],
            countryId: 82,
            phoneNumber: ['', Validators.required],
            personelPhoneNumber: ['', Validators.required],

        })
        this.turkeyAddressForm = this.formBuilder.group({
            adres: ['', Validators.required],
            countryId: 1,
            cityId: ['', Validators.required],
        })

    }

    createFamilyMemberForm() {
        return this.formBuilder.group({
            fullName: [''],
            genderId: [''],
            birthPlace: [''],
            birthDate: [''],
            memberTypeId: 3,
        });
    }

    addFamilyMember() {
        this.memberForm = this.formBuilder.group({
            familyMembers: this.formBuilder.array([this.createFamilyMemberForm()])
        });
    }


    checkYes(e, type) {
        if (type === 'haveDualNationality') {
            this.haveDualNationality = e.value;
            // (this.isUsePermission && this.otherForm.value.dateOfIstanbul == "") ? this.isFormDisabled = false : this.isFormDisabled = true;
        }
        else if (type === 'haveSpouseDualNationality') {
            this.haveSpouseDualNationality = e.value;
        }
        else if (type === 'haveAnotherFund') {
            this.haveAnotherFund = e.value;
            // (this.isRelativesJoin && (this.otherForm.value.proximityDegree == "" || this.otherForm.value.relativeName == "")) ? this.isFormDisabled = false : this.isFormDisabled = true;
        }
        else {
            this.isRegulation = e.checked;
        }
    }


    get familyMembers() {
        return this.memberForm.get('familyMembers') as FormArray;
    }

    get addresses() {
        return this.mainForm.get('addresses') as FormArray;

    }

    addNewFamilyMember() {
        this.familyMembers.push(this.createFamilyMemberForm());
    }

    deleteFamilyMember() {
        if (this.familyMembers.length > 0) {
            this.familyMembers.removeAt(this.familyMembers.length - 1);
        }

    }

    uploadFileEvent($event) {
        const selectedFile = $event.target.files[0];

        if (!selectedFile) {
            return;
        }

        const reader = new FileReader();

        reader.readAsDataURL(selectedFile);
        reader.onload = (_event) => {
            const formData = new FormData();
            formData.append('FormFile', selectedFile);

            this.applicationService.addPicture(formData)
                .subscribe(
                    (resp: any) => {
                        this.mainForm.patchValue({
                            fundPDFPath: resp
                        })
                    },
                    (error: any) => {

                    }
                );


        };

    }




    async saveForm() {

        this.mainForm.value.familyMembers = [];

        const formData = this.mainForm.value

        try {
            this.isLoading = true;
            if (this.spouseForm.valid && this.showSpouseForm) {
                formData.familyMembers.push(this.spouseForm.value);
            }

            if (this.showMemberForm && this.memberForm.valid) {
                this.memberForm.value.familyMembers.forEach((x) => formData.familyMembers.push(x));
            }

            formData.addresses.push(this.turkeyAddressForm.value);
            formData.addresses.push(this.switzerlandAddressForm.value);

            const data = await this.services.addFamily(formData).toPromise();
            this.isLoading = false;

            if (data.success == true) {
                await Swal.fire({
                    icon: 'success',
                    title: this.translationService.getValue('SUCCESS'),
                    text: this.translationService.getValue('BEI_DER_BEWERBUNG_IST_EIN_FEHLER_AUFGETRETEN'),
                    confirmButtonText: this.translationService.getValue('CLOSE')
                });

                location.reload();
            } else {
                const errorMessage = data.errors[0];

                await Swal.fire({
                    icon: 'error',
                    title: this.translationService.getValue('UNSUCCESS'),
                    text: errorMessage,
                    confirmButtonText: this.translationService.getValue('CLOSE')
                });
            }
        }
        catch (error) {
            this.isLoading = false;
            if (error && error.errors && error.errors.length > 0) {
                const errorMessage = error.errors[0];

                await Swal.fire({
                    icon: 'error',
                    title: this.translationService.getValue('UNSUCCESS'),
                    text: errorMessage,
                    confirmButtonText: this.translationService.getValue('CLOSE')
                });
            } else {
                await Swal.fire({
                    icon: 'error',
                    title: this.translationService.getValue('UNSUCCESS'),
                    text: this.translationService.getValue('BEI_DER_BEWERBUNG_IST_EIN_FEHLER_AUFGETRETEN'),
                    confirmButtonText: this.translationService.getValue('CLOSE')
                });
            }
        }
    }




}
