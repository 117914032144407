
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve
} from '@angular/router';
import { Observable } from 'rxjs';
import { Content } from 'src/app/models/content';
import { ContentService } from 'src/app/services/content.service';

@Injectable()
export class DonationResolver implements Resolve<any> {
    constructor(private contentService: ContentService) { }
    resolve(
        route: ActivatedRouteSnapshot
    ): Observable<Content> {
        const id = route.paramMap.get('id');
        return this.contentService.getContentById(id) as Observable<Content>;
    }
}