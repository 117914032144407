import { Injectable } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private stripePromise: Promise<Stripe | null>;

  getStripe(id: number): Promise<Stripe | null> {
    let key;
    try {
      key = environment.stripe.find(item =>
        Array.isArray(item.donationCategoryId)
          ? item.donationCategoryId.includes(id)
          : item.donationCategoryId === id
      )?.key;
       console.log(key);
    } catch (error) {
      
    }


    

    if (key) {
      this.stripePromise = loadStripe(key);
    } else {
      console.error('Stripe key not found for id:', id);
      this.stripePromise = Promise.resolve(null);
    }

    return this.stripePromise;
  }
}