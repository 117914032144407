import { Component, OnInit } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { MatRadioChange } from "@angular/material/radio";
import { TranslateService } from "@ngx-translate/core";
import { ValidatorService } from "angular-iban";
import { AppSetting } from "src/app/models/appsetting";
import { Bursary, BursaryPeriod } from "src/app/models/bursary";
import { CheckBoxOption } from "src/app/models/checkboxOption";
import { ApplicationService } from "src/app/services/application.service";
import { AppSettingService } from "src/app/services/appsetting.service";
import { ScholarshipService } from "src/app/services/scholarship.service";
import { TranslationService } from "src/app/services/translation.service";
import Swal from "sweetalert2";

@Component({
    selector: "app-scholarship",
    templateUrl: "./scholarship.component.html",
    styleUrls: ["./scholarship.component.scss"],
})
export class ScholarshipComponent implements OnInit {
    isLoading: boolean = false;
    personalInformationForm: UntypedFormGroup;
    addressForm: UntypedFormGroup;
    otherForm: UntypedFormGroup;
    familyForm: UntypedFormGroup;
    application: Bursary[] = [];
    isValid: boolean = false;
    isFormDisabled: boolean = true;
    checkBoxOption = new CheckBoxOption().checkBoxArray;
    bursaryTypes: any[] = [];
    fatherWork: boolean = false;
    motherWork: boolean = false;
    date = new Date();
    fileSize: { [key: string]: string } = {}
    languageCode: string = '';
    isActiveForm: boolean = false;
    bursaryInfo: string = '';


    constructor(
        private fb: FormBuilder,
        private scholarshipService: ScholarshipService,
        private applicationService: ApplicationService,
        private translationService: TranslationService,
        private appSettingService: AppSettingService
    ) {
        this.languageCode = localStorage.getItem('language');
    }

    ngOnInit(): void {
        this.createForm();
        this.getBursaryTypes();
        this.getPeriods();
    }

    getPeriods() {
        this.scholarshipService.getBursaryPeriod().subscribe((data: BursaryPeriod[]) => {
            const activePeriod = data.filter((period: BursaryPeriod) => period.isActive === true)
            
            if (activePeriod.length === 0) {
                this.isActiveForm = false;
            } else {
                const startDate = new Date(activePeriod[0].startDate);
                const endDate = new Date(activePeriod[0].finishDate);
                const today = new Date();

                if (today >= startDate && today < endDate) {
                    this.isActiveForm = true;
                } else {
                    this.isActiveForm = false;
                    this.getBursaryInfo();
                }
            }


        })
    }

    getBursaryInfo() {
        this.appSettingService.getAppSetting().subscribe((setting: AppSetting[]) => {
            this.bursaryInfo = setting.filter((s: any) => s.key === `bursdonemibittimesaji${this.languageCode}`)[0].value
            
            
        })
    }

    onSelectionChange(event: MatRadioChange, id: number) {
        const controlsToUpdate = id === 1 ? ['fatherWorkPlace', 'fatherJob'] : id === 2 ? ['motherJob', 'motherWorkPlace'] : ['fatherWorkPlace', 'fatherJob', 'motherJob'];

        controlsToUpdate.forEach(controlName => {
            const control = this.familyForm.get(controlName);
            if (event?.value) {
                control?.setValidators(Validators.required);
            } else {
                control?.clearValidators();
            }
            control?.updateValueAndValidity();
        });

        if (id === 1) {
            this.fatherWork = event?.value;
        } else if (id === 2) {
            this.motherWork = event?.value;
        }
    }


    getBursaryTypes() {
        this.scholarshipService.getBursaryTypes().subscribe((res: any) => {
            this.bursaryTypes = res
        });
    }

    checkForm(form: AbstractControl): boolean {
        this.isValid = form.dirty && form.valid && form.touched;
        return this.isValid;
    }

    nextTab(currentTabIndex: number) {
        currentTabIndex++;
        const tabId = `tab${currentTabIndex + 1}`;
        const tabRadio = document.getElementById(tabId) as HTMLInputElement;
        if (tabRadio) {
            tabRadio.click();
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 0);
        }
    }

    createForm() {
        this.personalInformationForm = this.fb.group({
            bursaryTypeId: ['', [Validators.required]],
            fullName: ['', [Validators.required]],
            identificationNumber: ['', [Validators.minLength(11), Validators.required]],
            birthDate: ['', [Validators.required]],
            birthPlace: ['', [Validators.required]],
        });

        this.addressForm = this.fb.group({
            provinceAndDistrict: ['', [Validators.required]],
            corresponceAdress: ['', [Validators.required]],
            phoneNumber: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
        });

        this.familyForm = this.fb.group({
            fatherFullName: ['', [Validators.required]],
            fatherJob: ['', [Validators.required]],
            doesFatherWork: ['', [Validators.required]],
            fatherWorkPlace: ['', [Validators.required]],
            fatherMonthlyEarnings: ['', [Validators.required]],
            motherFullName: ['', [Validators.required]],
            motherWorkPlace: ['', [Validators.required]],
            motherMonthlyEarnings: ['', [Validators.required]],
            motherJob: ['', [Validators.required]],
            doesMotherWork: ['', [Validators.required]],
            dependOnPersonCount: ['', [Validators.required]],
            familyMonthlyIncome: ['', [Validators.required]],
        });

        this.otherForm = this.fb.group({
            lastGraduatedSchool: ['', [Validators.required]],
            attendSchool: ['', [Validators.required]],
            section: ['', [Validators.required]],
            bankName: ['', [Validators.required]],
            iban: ['', [Validators.required, ValidatorService.validateIban]],
            incomeDeclarationDocument: ['', [Validators.required]],
            identificationPhotoCopy: ['', [Validators.required]],
            currentStudentCertificate: ['', [Validators.required]],
            identityRegisterCopy: ['', [Validators.required]],
        });
    }


    createMainBuildObject(): any {
        const application: Bursary = {
            bursaryTypeId: this.personalInformationForm.get("bursaryTypeId").value,
            fullName: this.personalInformationForm.get("fullName").value.toUpperCase(),
            identificationNumber: this.personalInformationForm.get("identificationNumber").value,
            birthDate: this.personalInformationForm.get("birthDate").value,
            birthPlace: this.personalInformationForm.get("birthPlace").value.toUpperCase(),

            provinceAndDistrict: this.addressForm.get("provinceAndDistrict").value,
            phoneNumber: this.addressForm.get("phoneNumber").value,
            email: this.addressForm.get("email").value,
            corresponceAdress: this.addressForm.get("corresponceAdress").value,

            fatherFullName: this.familyForm.get("fatherFullName").value.toUpperCase(),
            fatherJob: this.familyForm.get("fatherJob").value.toUpperCase(),
            doesFatherWork: this.familyForm.get("doesFatherWork").value,
            fatherWorkPlace: this.familyForm.get("fatherWorkPlace").value.toUpperCase(),
            motherWorkPlace: this.familyForm.get("fatherWorkPlace").value.toUpperCase(),
            fatherMonthlyEarnings: String(this.familyForm.get("fatherMonthlyEarnings").value),
            motherMonthlyEarnings: String(this.familyForm.get("fatherMonthlyEarnings").value),
            motherFullName: this.familyForm.get("motherFullName").value.toUpperCase(),
            motherJob: this.familyForm.get("motherJob").value.toUpperCase(),
            doesMotherWork: this.familyForm.get("doesMotherWork").value,
            dependOnPersonCount: this.familyForm.get("dependOnPersonCount").value,
            familyMonthlyIncome: String(this.familyForm.get("familyMonthlyIncome").value),

            lastGraduatedSchool: this.otherForm.get("lastGraduatedSchool").value.toUpperCase(),
            attendSchool: this.otherForm.get("attendSchool").value.toUpperCase(),
            section: this.otherForm.get("section").value.toUpperCase(),
            bankName: this.otherForm.get("bankName").value.toUpperCase(),
            iban: this.otherForm.get("iban").value,
            incomeDeclarationDocument: this.otherForm.get("incomeDeclarationDocument").value,
            identificationPhotoCopy: this.otherForm.get("identificationPhotoCopy").value,
            currentStudentCertificate: this.otherForm.get("currentStudentCertificate").value,
            identityRegisterCopy: this.otherForm.get("identityRegisterCopy").value
        };
        return application;
    }

    uploadFileEvent($event) {
        const selectedFile = $event.target.files[0];
        let formControl = $event.target.id;


        if (!selectedFile) {
            return;
        }

        if (selectedFile.size > 262144000) {//250mb
            this.fileSize[formControl] = this.translationService.getValue('BIG_FILE_SIZE');
        }
        else {
            this.fileSize[formControl] = ''
        }

        const reader = new FileReader();

        reader.readAsDataURL(selectedFile);
        reader.onload = (_event) => {
            const formData = new FormData();
            formData.append('FormFile', selectedFile);
            formData.append('FolderName', 'Pictures/Bursary')

            this.applicationService.addGenericUpload(formData)
                .subscribe(
                    (resp: any) => {

                        this.otherForm.get(formControl).patchValue(resp)
                    },
                    (error: any) => {
                        Swal.fire({
                            icon: "error",
                            title: "Hata",
                            text: this.translationService.getValue('UPLOAD_ERROR_IMAGE'),
                            confirmButtonText: "Kapat",
                        });
                    }
                );


        };

    }

    saveForm() {
        this.isLoading = true;

        if (this.personalInformationForm.valid && this.addressForm.valid && this.otherForm.valid && this.familyForm.valid) {
            this.application = this.createMainBuildObject();

            this.scholarshipService.addBursaryForm(this.application).subscribe((data: any) => {
                this.isLoading = false;
                if (data) {
                    Swal.fire({
                        icon: 'success',
                        title: this.translationService.getValue('SUCCESS'),
                        text: this.translationService.getValue('IHRE_ANMELDUNG_WURDE_ERFOLGREICH_GESENDET'),
                        confirmButtonText: this.translationService.getValue('CLOSE'),

                    }).then((success) => {
                        window.location.reload();
                    });
                }

            }, (error: any) => {
                this.isLoading = false;
                {
                    Swal.fire({
                        icon: 'error',
                        title: this.translationService.getValue('UNSUCCESS'),
                        text: this.translationService.getValue('BEI_DER_BEWERBUNG_IST_EIN_FEHLER_AUFGETRETEN'),
                        confirmButtonText: this.translationService.getValue('CLOSE'),
                    }
                    )
                }
            }
            )
        }
        else {
            this.isLoading = false;
            Swal.fire({
                icon: 'error',
                title: this.translationService.getValue('UNSUCCESS'),
                text: this.translationService.getValue('BEI_DER_BEWERBUNG_IST_EIN_FEHLER_AUFGETRETEN'),
                confirmButtonText: this.translationService.getValue('CLOSE')
            }
            )
        }
    }

}