// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: "https://proje9.vakifglobal.com/api/",
  // serverUrl: "https://proje9.vakifglobal.com/",

  apiUrl:"https://api.itdv.ch/api/",
  serverUrl: "https://api.itdv.ch/",

  // apiUrl:"https://localhost:44313/api/",
  // serverUrl: "https://localhost:44313/",


  // ========================STRIPE API for DONATIONS============================== //
  stripe: {
    VictimPublishedKey: "pk_live_bn9fitP2hFWiKOpjXHdynlgm00xJXKSSUj",
    GeneralPublishedKey: "pk_live_JaOCNy4X1iQsRNtjQpxCJXGu00g46cD0pj", //zekat fitre fidye sadaka
    AfricaPublishedKey: "pk_live_51KCLHfJXNXn79HymIlWahvhaVXVdppZndHE7XawZVsffktAwk1imkdHWcYdo1DMut6KswDwDQe3n4JeOWc7okLU000oJClQkuG",
    testPublishableKey: "pk_test_51PdYdERobXMG4wGuJeOyg6GnpbtYT61MvPClgo4gnOPkQa4lnPGAwFhr6lhtrpEp7JkkLVyypwagMLzF9mYJMM6l00owoogDAj",
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
