import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { forkJoin } from 'rxjs';
import { Content } from 'src/app/models/content';
import { ContentService } from 'src/app/services/content.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-donation-category',
  templateUrl: './donation-category.component.html',
  styleUrls: ['./donation-category.component.scss']
})
export class DonationCategoryComponent {
  donationCategory: Content[] = [];
  news: Content[] = [];
  imgUrl: string = environment.serverUrl
  blogSlides: OwlOptions = {
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: true,
    autoplay: false,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 2
      },
      768: {
        items: 3
      },
      1200: {
        items: 4
      }
    }
  }

  constructor(private contentService: ContentService) { }

  ngOnInit() {
    this.getAll();

  }

  getAll() {
    const services = [
      this.contentService.getAllDonations(),
      this.contentService.getAllNews()
    ]
    forkJoin(services).subscribe(([categories, news]) => {
      this.donationCategory = categories.data
      this.news = news.data
    })
  }


 
}
