
import { Component, HostListener, OnInit } from '@angular/core';
import { prayerTime } from 'src/app/models/prayerTime';
import { environment } from 'src/environments/environment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { services } from 'src/app/services/services.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    // smallSlider: any;
    photoUrl: string = environment.serverUrl


    smallSlider =
        [
            {
                url: 'assets/img/itdv_aile.svg'
            },
            {
                url: 'assets/img/itdv_cocuk.svg'
            },
            {
                url: 'assets/img/itdv_genclik.svg'
            },
            {
                url: 'assets/img/itdv_kadin.svg'
            },
            {
                url: 'assets/img/itdv_yayin.svg'
            },



        ]

    constructor(private services: services) { }
    ngOnInit(): void {
        this.getSmallSlider();
    }


    getSmallSlider() {
        // this.services.getAllSmallSlider().subscribe((data: any) => {
        //     this.smallSlider = data.data.filter(slider => slider.isActive == true && slider.isDeleted == false).sort((a, b) => a.order - b.order);
        //     
        // })
    }


    partnerSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 0,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ],
        responsive: {
            0: {
                items: 4
            },
            576: {
                items: 3
            },
            768: {
                items: 4
            },
            1200: {
                items: 9
            }
        }
    }





























    // prayerTime: prayerTime;
    // isSticky: boolean = false;
    // selectedCity: any;
    // selectedCountry: any;
    // // country: any;
    // // cities: any;
    // langcode: any;
    // submenus: any;
    // //   orphanMenus: any;
    // news: any[] = [];
    // // photoUrl: string = environment.serverUrl

    // cities = [

    //         { name: 'Paris', latitude: 48.8566, longitude: 2.3522 },
    //         { name: 'Marseille', latitude: 43.2965, longitude: 5.3698 },
    //         { name: 'Lyon', latitude: 45.7578, longitude: 4.8320 },
    //         { name: 'Toulouse', latitude: 43.6047, longitude: 1.4442 },
    //         { name: 'Nice', latitude: 43.7102, longitude: 7.2620 },
    //         { name: 'Nantes', latitude: 47.2184, longitude: -1.5536 },
    //         { name: 'Strasbourg', latitude: 48.5734, longitude: 7.7521 },
    //         { name: 'Montpellier', latitude: 43.6109, longitude: 3.8772 },
    //         { name: 'Bordeaux', latitude: 44.8378, longitude: -0.5792 },
    //         { name: 'Lille', latitude: 50.6292, longitude: 3.0573 },
    //         { name: 'Rennes', latitude: 48.1173, longitude: -1.6778 },
    //         { name: 'Reims', latitude: 49.4432, longitude: 1.0999 },
    //         { name: 'Le Havre', latitude: 49.4938, longitude: 0.1077 },
    //         { name: 'Toulon', latitude: 43.1242, longitude: 5.9280 },
    //         { name: 'Grenoble', latitude: 45.1885, longitude: 5.7245 },
    //         { name: 'Dijon', latitude: 47.3220, longitude: 5.0415 },
    //         { name: 'Angers', latitude: 47.4784, longitude: -0.5632 },
    //         { name: 'Nîmes', latitude: 43.8367, longitude: 4.3601 },
    //         { name: 'Aix-en-Provence', latitude: 43.5297, longitude: 5.4474 },
    //         { name: 'Saint-Étienne', latitude: 45.4397, longitude: 4.3872 }

    // ];

    // country = [

    //         { name: 'Fransa', latitude: 48.8566, longitude: 2.3522 },


    // ];

    // orphanMenus = [
    //     {
    //         title: 'Anasayfa',
    //     },
    //     {
    //         title: 'Cenaze Fonu',
    //         submenus: [
    //             { name: 'Cenaze Fonu Bilgilendirme', url: '/hac-kayit' },
    //             { name: 'Cenaze Fonu Ödeme', url: 'https://www.payasso.fr/ditib/cenazefonu1' },
    //         ]
    //     },
    //     {
    //         title: 'Haberler',url:'din-gorevlilerimiz'
    //     },
    //     {
    //         title: 'Hutbeler',url:'din-gorevlilerimiz'

    //     },
    //     {
    //         title: 'Kurumsal',
    //         submenus: [
    //             { name: 'Hakkımızda', url: '/hizmetler' },
    //             { name: 'Hedeflerimiz', url: '/hizmetler' },
    //             { name: 'İlkelerimiz', url: '/hizmetler' },
    //             { name: 'Kuruluş ve Teşkilat Yapısı', url: '/hizmetler' },
    //             { name: 'Yönetim Kurulu', url: '/hizmetler' },
    //             { name: 'Denetleme Kurulu', url: '/hizmetler' },
    //         ]

    //     },
    //     {
    //         title: 'İletişim',url:'/contact',
    //         submenus: [
    //             { name: 'Derneklerimiz', url: '/hizmetler' },
    //             { name: 'Kardeş Kuruluşlar', url: '/hizmetler' },
    //             { name: 'Bağlantılı Kuruluşlar', url: '/hizmetler' },
    //             { name: 'Ditib ile İletişim', url: '/contact' },
    //         ]

    //     },
    //     {
    //         title: 'Bağış',
    //         submenus: [
    //             { name: 'Kurban', url: '/hizmetler' },
    //             { name: 'Zekat', url: '/hizmetler' },
    //             { name: 'Fitre', url: '/hizmetler' },
    //             { name: 'Fidye', url: '/hizmetler' },
    //             { name: 'Genel Bağış', url: '/hizmetler' },

    //         ]
    //     }
    // ];

    // @HostListener('window:scroll', ['$event'])
    // checkScroll() {
    //     const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    //     if (scrollPosition >= 50) {
    //         this.isSticky = true;
    //     } else {
    //         this.isSticky = false;
    //     }
    // }

    // constructor(private service: services) { }

    // classApplied = false;
    // toggleClass() {
    //     this.classApplied = !this.classApplied;
    // }

    // classApplied2 = false;
    // toggleClass2() {
    //     this.classApplied2 = !this.classApplied2;
    // }

    // classApplied3 = false;
    // toggleClass3() {
    //     this.classApplied3 = !this.classApplied3;
    // }

    // getPrayerTime(long, lat) {
    //     this.service.getPrayerTimes(long, lat).subscribe((resp: any) => {
    //         this.prayerTime = resp.daily.data[0];
    //     })
    // }

    // getCountry() {
    //     this.service.getFrontAllCountry().subscribe((resp: any) => {
    //         this.country = resp;
    //         if (this.country.length > 0) {
    //             this.selectedCountry = this.country[0].langcode;
    //             this.getCityByLangCode(this.selectedCountry);
    //         }
    //     })
    // }


    // onCountrySelected(e: any) {
    //     this.selectedCountry = e.target.value
    //     this.getCityByLangCode(this.selectedCountry);
    // }

    // getCityByLangCode(langcode: string) {
    //     this.service.getFrontCountryByLangcode(langcode).subscribe((data: any) => {
    //         this.cities = data[0].cities;
    //         this.getPrayerTime(this.cities[0].longitude, this.cities[0].latitude)
    //     })
    // }

    // onCitySelected(x: any) {
    //     this.selectedCity = x.target.value
    //     this.service.getFrontCityById(this.selectedCity).subscribe((data: any) => {
    //         this.getPrayerTime(data.longitude, data.latitude);
    //     })
    // }

    //   getAllFrontMenus() {
    //       this.service.getAllFrontMenuRecord().subscribe((data: any) => {
    //           this.submenus = data.filter(x => x.parentId !== null && x.isActive == true);
    //           this.orphanMenus = data.filter(menu => {
    //               return menu.parentId == null && !data.some(submenu => submenu.id === menu.parentId && submenu.isActive == true);
    //           });
    //       })
    //   }

    // hasSubmenus(menu: any): boolean {
    //     return this.submenus.some(submenu => submenu.parentId === menu.id);
    // }

    // getSubmenus(menu: any): any[] {
    //     return this.submenus.filter(submenu => submenu.parentId === menu.id);
    // }

    // windowScrolled: boolean | undefined;

    // @HostListener("window:scroll", [])
    // onWindowScroll() {
    //     this.windowScrolled = window.scrollY > 100;
    // }
    // // Navigation
    // navMethod: boolean = false;
    // toggleNav() {
    //     this.navMethod = !this.navMethod;
    // }
    // // Search
    // searchMethod: boolean = false;
    // toggleSearch() {
    //     this.searchMethod = !this.searchMethod;
    // }
    // // Mobile 
    // open: boolean = false;
    // trigger(item: { open: boolean; }) {
    //     item.open = !item.open;
    // }
    // // Add class on resize and onload window
    // visible: boolean = false;
    // breakpoint: number = 991;
    // public innerWidth: any;
    // detectHeader() {
    //     this.innerWidth = window.innerWidth;
    //     this.visible = this.innerWidth >= this.breakpoint;
    // }


    // blogSlides: OwlOptions = {
    //     loop: false,
    //     nav: true,
    //     dots: false,
    //     autoplayHoverPause: true,
    //     autoplay: false,
    //     margin: 30,
    //     navText: [
    //         "<i class='bx bx-left-arrow-alt'></i>",
    //         "<i class='bx bx-right-arrow-alt'></i>"
    //     ],
    //     responsive: {
    //         0: {
    //             items: 1
    //         },
    //         768: {
    //             items: 2
    //         },
    //         1200: {
    //             items: 3
    //         }
    //     }
    // }


















}
