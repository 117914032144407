<section class="latest-news section-gap secondary-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-7">
                <div class="section-title white-color text-center mb-40">
                    <h2 class="title" style="color:white">{{'NEWS'|translate}}</h2>
                    <!-- <p class="text-color-2">
                        Sende bağış yaparak destek ol
                    </p> -->
                </div>
            </div>
        </div>
    </div>
    <div class="mt-30">
        <owl-carousel-o [options]="blogSlides">
            <ng-template carouselSlide *ngFor="let item of news ; let i = index">
                <a routerLink="/haber-detay/{{item.id}}">
                    <div class="single-blog-grid mb-30">
                        <img routerLink="/haber-detay/{{item.id}}" [src]="imgUrl+item.contentImages[0].imagePath"
                            [alt]="item.title">
                        <a class="cat">{{item.subject}}</a>
                        <div class="blog-grid-overlay">
                            <h5 class="title"><a>{{item.title | slice:0:200}}</a>
                            </h5>

                        </div>
                    </div>
                </a>
            </ng-template>

        </owl-carousel-o>
    </div>
</section>

<div class="container mt-50">
    <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-7">
            <div class="section-title white-color text-center mb-40">
                <h2 class="title mb-20 ">{{'DONATE'|translate}}</h2>
                <p class="text-color-2">
                    {{'SUPPORT_BY_DONATING'|translate}}
                </p>
            </div>
        </div>
    </div>
    <div>

        <div class="wrapper">
            <div class="categories" *ngFor="let item of donationCategory" routerLink="/bagis/{{item.id}}">
                <a><p class="title">{{item.title}}</p></a>
                <img [src]="imgUrl+item?.contentImages[0]?.imagePath" [alt]="item?.img">
            </div>

        </div>
    </div>

</div>