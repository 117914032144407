<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">

            <h2>{{'UMRAH_APPLICATION'|translate}}</h2>
        </div>
    </div>
</div>

<div class="instructor-area pt-70 pb-70">
    <div class="container">
        <div class="alert alert-warning" *ngIf="info.length>0">
            <strong>{{'INFO'|translate}}</strong><br>
            <ng-container *ngFor="let item of info">
                {{item}}<br>
            </ng-container>
        </div>
        <div class="container application">

            <!-- <h3 >Hac Başvurusu</h3> -->
            <div class="tab-wrap application">

                <input type="radio" id="tab1" name="tabGroup1" class="tab" checked>
                <label for="tab1">{{'PERSONAL_INFORMATION'|translate}}</label>

                <input type="radio" id="tab2" name="tabGroup1" class="tab"
                    [disabled]="!this.checkForm(personalInformationForm)">
                <label for="tab2">{{'PASSPORT_INFORMATION'|translate}}</label>

                <input type="radio" id="tab3" name="tabGroup1" class="tab" [disabled]="!this.checkForm(passportForm)">
                <label for="tab3">{{'ADDITIONAL_INFORMATION'|translate}}</label>

                <div class="tab__content personal">
                    <h3>{{'PERSONAL_INFORMATION'|translate}}</h3>
                    <form class="row col md-12" [formGroup]="personalInformationForm">

                        <div class="col-md-6">
                            <label for="firstName" class="form-label">{{ 'FIRST_NAME' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" formControlName="firstName"
                                placeholder="{{ 'FIRST_NAME' | translate }}" required>
                            <div *ngIf="personalInformationForm.get('firstName')?.invalid && (personalInformationForm.get('firstName')?.dirty || personalInformationForm.get('firstName')?.touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="lastName" class="form-label">{{ 'LAST_NAME' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" formControlName="lastName"
                                placeholder="{{ 'LAST_NAME' | translate }}" required>
                            <div *ngIf="personalInformationForm.get('lastName')?.invalid && (personalInformationForm.get('lastName')?.dirty || personalInformationForm.get('lastName')?.touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="motherName" class="form-label">{{ 'MOTHER_NAME' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" formControlName="motherName"
                                placeholder="{{ 'MOTHER_NAME' | translate }}" required>
                            <div *ngIf="personalInformationForm.get('motherName')?.invalid && (personalInformationForm.get('motherName')?.dirty || personalInformationForm.get('motherName')?.touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="fatherName" class="form-label">{{ 'FATHER_NAME' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" formControlName="fatherName"
                                placeholder="{{ 'FATHER_NAME' | translate }}" required>
                            <div *ngIf="personalInformationForm.get('fatherName')?.invalid && (personalInformationForm.get('fatherName')?.dirty || personalInformationForm.get('fatherName')?.touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">{{ 'TC_IDENTIFICATION_NUMBER' |
                                translate
                                }}</label>
                            <input type="text" class="form-control" formControlName="identificationNumber"
                                placeholder="xxxxxxxxxxx" maxlength="11" onlyNumber>
                            <div *ngIf="personalInformationForm.get('identificationNumber')?.invalid && (personalInformationForm.get('identificationNumber').dirty || personalInformationForm.get('identificationNumber').touched)"
                                class="alert">
                                <div
                                    *ngIf=" personalInformationForm.get('identificationNumber')?.hasError('minlength')">
                                    {{'PLEASE_ENTER_11_DIGITS'|translate}}
                                </div>

                            </div>

                        </div>
                        <div class="col-md-6">
                            <label for="genderId" class="form-label">{{ 'GENDER' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <select id="genderId" class="form-select" formControlName="genderId">
                                <option value="" selected>{{ 'SELECT' | translate }}</option>
                                <option *ngFor="let item of gender" [ngValue]="item.id">{{ item.name | translate}}
                                </option>
                            </select>
                            <div *ngIf="personalInformationForm.get('genderId')?.invalid && (personalInformationForm.get('genderId')?.dirty || personalInformationForm.get('genderId')?.touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>

                        </div>
                        <div class="col-md-6">
                            <label for="birthDay" class="form-label">{{ 'BIRTHDAY' | translate }}<label
                                    class="text-danger fs-5">*</label><label class="alert">
                                    {{'FULL_DATE'|translate}}</label></label>
                            <input type="date" class="form-control" id="birthDay" formControlName="birthDay"
                                max="{{date | date:'yyyy-MM-dd'}}">
                            <div *ngIf="personalInformationForm.get('birthDay').invalid && (personalInformationForm.get('birthDay').dirty || personalInformationForm.get('birthDay').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="birthPlace" class="form-label">{{ 'BIRTH_PLACE' | translate }}</label>
                            <input type="text" class="form-control" id="birthPlace" formControlName="birthPlace"
                                placeholder="{{ 'BIRTH_PLACE' | translate }}">
                            <div *ngIf="personalInformationForm.get('birthPlace').invalid && (personalInformationForm.get('birthPlace').dirty || personalInformationForm.get('birthPlace').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="maritalStatusId" class="form-label">{{ 'MARITAL_STATUS' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <select id="maritalStatusId" class="form-select" formControlName="maritalStatusId">
                                <option value="" disabled selected>{{ 'SELECT' | translate }}</option>
                                <option *ngFor="let item of maritalStatus" [ngValue]="item.id">{{ item.name |translate}}
                                </option>
                            </select>
                            <div *ngIf="personalInformationForm.get('maritalStatusId')?.invalid && (personalInformationForm.get('maritalStatusId')?.dirty || personalInformationForm.get('maritalStatusId')?.touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="email" class="form-label">{{ 'EMAIL' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="email" class="form-control" id="email" formControlName="email"
                                placeholder="{{ 'EMAIL' | translate }}">
                            <div *ngIf="personalInformationForm.get('email')?.invalid && (personalInformationForm.get('email').dirty || personalInformationForm.get('email').touched)"
                                class="alert">
                                <div *ngIf="personalInformationForm.get('email').errors?.required">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                </div>
                                <div *ngIf="personalInformationForm.get('email')?.errors?.email" class="alert">
                                    {{ 'PLEASE_ENTER_A_VALID_EMAIL' | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="phone" class="form-label">{{ 'PHONE_NUMBER' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" maxlength="15" class="form-control" id="phone" formControlName="phone"
                                placeholder="{{ 'PHONE_NUMBER' | translate }}" onlyNumber>
                            <div *ngIf="personalInformationForm.get('phone').invalid && (personalInformationForm.get('phone').dirty || personalInformationForm.get('phone').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="homePhone" class="form-label">{{ 'HOME_PHONE' | translate }}</label>
                            <input type="text" maxlength="15" class="form-control" id="phone"
                                formControlName="homePhone" placeholder="{{ 'HOME_PHONE' | translate }}" onlyNumber>

                        </div>

                        <div class="col-md-4">
                            <label for="address" class="form-label">{{ 'RESIDANCE_ADDRESS' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="address" formControlName="address"
                                placeholder="{{ 'RESIDANCE_ADDRESS' | translate }}">
                            <div *ngIf="personalInformationForm.get('address')?.invalid && (personalInformationForm.get('address').dirty || personalInformationForm.get('address').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-4">
                            <label for="postCode" class="form-label">{{ 'POST_CODE' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="postCode" formControlName="postCode"
                                placeholder="{{ 'POST_CODE' | translate }}">
                            <div *ngIf="personalInformationForm.get('postCode')?.invalid && (personalInformationForm.get('postCode').dirty || personalInformationForm.get('postCode').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-4">
                            <label for="city" class="form-label">{{ 'CITY' | translate }}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="city" formControlName="city"
                                placeholder="{{ 'CITY' | translate }}">
                            <div *ngIf="personalInformationForm.get('city')?.invalid && (personalInformationForm.get('city').dirty || personalInformationForm.get('city').touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary" (click)="nextTab(0)"
                            [disabled]="!this.checkForm(personalInformationForm)">
                            <div *ngIf="!this.checkForm(personalInformationForm)"><label class=" fs-5">*</label>{{
                                'FILL_IN_ALL_FIELDS_TO_PROCEED' | translate
                                }}</div>
                            <div *ngIf="this.checkForm(personalInformationForm)"> {{ 'NEXT' | translate }}</div>
                        </button>
                    </form>

                </div>

                <div class="tab__content">
                    <h3 application>{{'PASSPORT_INFORMATION'|translate}}</h3>
                    <form class="row col md-12" [formGroup]="passportForm">
                        <div class="col-md-6">
                            <label for="passportNumber" class="form-label">{{'PASSPORT_NUMBER'|translate}}<label
                                    class="text-danger fs-5">*</label></label>
                            <input type="text" class="form-control" id="passportNumber" formControlName="passportNumber"
                                placeholder="{{'PASSPORT_NUMBER'|translate}}"
                                style="text-transform: uppercase !important;">
                            <div *ngIf="passportForm.get('passportNumber').invalid && (passportForm.get('passportNumber').dirty || passportForm.get('passportNumber').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="passportGivenDate" class="form-label">{{'PASSPORT_GIVEN_DATE'|translate}}<label
                                    class="text-danger fs-5">*</label> <label class="alert">
                                    {{'FULL_DATE'|translate}}</label></label>
                            <input type="date" class="form-control" id="passportGivenDate"
                                max="{{date | date:'yyyy-MM-dd'}}" placeholder="{{'PASSPORT_GIVEN_DATE'|translate}}"
                                formControlName="passportGivenDate">
                            <div *ngIf="passportForm.get('passportGivenDate').invalid && (passportForm.get('passportGivenDate').dirty || passportForm.get('passportGivenDate').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="passportValidityDate"
                                class="form-label">{{'PASSPORT_EXPIRATION_DATE'|translate}}<label
                                    class="text-danger fs-5">*</label> <label class="alert">
                                    {{'FULL_DATE'|translate}}</label></label>
                            <input type="date" class="form-control" id="passportValidityDate"
                                placeholder="{{'PASSPORT_EXPIRATION_DATE'|translate}}"
                                min="{{date | date:'yyyy-MM-dd'}}" formControlName="passportValidityDate"
                                (change)="checkpassportValidityDate()">
                            <div
                                *ngIf="passportForm.get('passportValidityDate').invalid && 
                            (passportForm.get('passportValidityDate').dirty || passportForm.get('passportValidityDate').touched)">

                                <div *ngIf="passportForm.get('passportValidityDate').hasError('required')"
                                    class="alert">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                </div>
                                <div *ngIf="passportForm.get('passportValidityDate').hasError('expired')" class="alert">
                                    {{'YOUR_PASSPORT_HAS_EXPIRED'|translate}}
                                </div>
                                <div *ngIf="passportForm.get('passportValidityDate').hasError('tooSoon')" class="alert">
                                    {{'PASSPORT_DATE_SHORT'|translate}}
                                </div>


                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="passportCountryName"
                                class="form-label">{{'COUNTRY_NATIONALITY'|translate}}<label
                                    class="text-danger fs-5">*</label></label>
                            <!-- <input type="text" class="form-control" id="passportCountryName"
                                placeholder="{{'COUNTRY_NATIONALITY'|translate}}" formControlName="passportCountryName"> -->

                            <select formControlName="passportCountryName" name="passportCountryName"
                                class="form-control">
                                <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                <option *ngFor="let item of countries" [ngValue]="item.name">
                                    {{item.langcode}} {{item.name | translate}}</option>
                            </select>


                            <div *ngIf="passportForm.get('passportCountryName').invalid && (passportForm.get('passportCountryName').dirty || passportForm.get('passportCountryName').touched)"
                                class="alert">

                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}

                            </div>
                        </div>
                        <!-- <div class="col-md-12">
                            <label for="passportPicture" class="form-label">Pasaport Fotoğrafı </label>
                            <input type="file" class="form-control" id="passportPicture"
                                (change)="uploadFileEvent($event,'pass')">
                        </div> -->

                        <button type="submit" class="btn btn-primary" (click)="nextTab(1)"
                            [disabled]="!this.checkForm(passportForm)">
                            <div *ngIf="!this.checkForm(passportForm)">{{
                                'FILL_IN_ALL_FIELDS_TO_PROCEED' | translate
                                }}</div>
                            <div *ngIf="this.checkForm(passportForm)"> {{ 'NEXT' | translate }}</div>
                        </button>
                    </form>
                </div>




                <div class="tab__content">
                    <h3>{{'ADDITIONAL_INFORMATION'|translate}}</h3>
                    <form class="row col-md-12" [formGroup]="otherForm">
                        <div class="col-md-6">
                            <label for="roomType" class="form-label">{{'PREFERRED_UMRAH_TOUR'|translate}}<label
                                    class="text-danger fs-5">*</label></label>
                            <select id="roomType" formControlName="periodId" class="form-select"
                                (change)="getRoomTypes($event)">
                                <option value="" disabled selected>{{'SELECT'|translate}}</option>
                                <option *ngFor="let item of periods" [value]="item.id">{{item.name}}
                                </option>
                            </select>
                            <div *ngIf="otherForm.get('periodId')?.invalid && (otherForm.get('periodId').dirty || otherForm.get('periodId').touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>


                        </div>
                        <div class=" col-md-6">
                            <label for="roomType" class="form-label">{{'ROOM_TYPE'|translate}}<label
                                    class="text-danger fs-5">*</label></label>
                            <select id="roomType" formControlName="roomTypeId" class="form-select"
                                (change)="addNewRoomMates($event)">
                                <option value="" disabled selected>{{ periodSelected ?
                                    ('SELECT' | translate) : ('SELECT_PERIOD_FIRST' | translate) }}</option>

                                <ng-container *ngIf="periodSelected">
                                    <option *ngFor="let item of roomTypes" [value]="item.roomTypeId">
                                        {{item?.roomTypeName}} {{'FOR_PERSON'|translate}} - {{item?.amount}} CHF
                                    </option>
                                </ng-container>

                            </select>
                            <div *ngIf="otherForm.get('roomTypeId')?.invalid && (otherForm.get('roomTypeId').dirty || otherForm.get('roomTypeId').touched)"
                                class="alert">
                                {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                            </div>


                        </div>

                        <div class="row col-md-12" formArrayName="roomMates" *ngIf="roomMatesNumber">
                            <div class="col-md-6" *ngFor="let item of roomMates.controls; let i=index">
                                <h2 class="hajj-form">{{i+2}}. {{'PERSON'|translate}}</h2>
                                <div [formGroupName]="i" class="row">

                                    <div class="col-md-6">
                                        <label for="firstName" class="form-label">{{ 'FIRST_NAME' | translate
                                            }}</label>
                                        <input type="text" class="form-control" formControlName="firstName"
                                            placeholder="{{ 'FIRST_NAME' | translate }}">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="lastName" class="form-label">{{ 'LAST_NAME' | translate
                                            }}</label>
                                        <input type="text" class="form-control" formControlName="lastName"
                                            placeholder="{{ 'LAST_NAME' | translate }}">
                                    </div>
                                </div>


                            </div>

                        </div>

                        <div class="row col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="guidanceLanguageId" class="form-label">{{ 'GUIDANCE_LANGUAGE' |
                                        translate
                                        }}<label class="text-danger fs-5">*</label></label>
                                    <select id="guidanceLanguageId" formControlName="guidanceLanguageId"
                                        class="form-select">
                                        <option value="" disabled selected>{{'SELECT'|translate}}</option>
                                        <option *ngFor="let item of guidanceLanguage" [value]="item.id">
                                            {{item?.name | translate}}
                                        </option>

                                    </select>
                                </div>
                            </div>

                        </div>



                        <section class="example-section">
                            <mat-checkbox class="example-margin" (change)="checkYes($event)"><a
                                    [href]="photoUrl+regulation" target="_blank"><u
                                        class="text-danger">{{'REGULATION'|translate}}</u></a>
                                {{'I_HAVE_READ_AND_ACCEPT'|translate}}
                                <label class="text-danger fs-5">*</label></mat-checkbox>
                        </section>
                        <button
                            [disabled]="isLoading || !personalInformationForm.valid || !otherForm.valid || !isRegulation ||!passportForm"
                            (click)="saveForm() " class="btn btn-primary">
                            <span *ngIf="!isLoading">{{'SUBMIT'|translate}}</span>
                            <span *ngIf="isLoading">
                                <i class="fa fa-spinner fa-spin"></i> {{'SENDING'|translate}}
                            </span>
                        </button>

                    </form>


                </div>

            </div>

        </div>
    </div>
</div>