
<div class="page-title-area page-title-style-two item-bg1">
    <!-- <img *ngIf="content?.bannerPhoto"  [src]="photoUrl+content?.bannerPhoto" alt=""> -->
    <div class="container">
        <div class="nine">
            <h1>{{content?.title}}</h1>
        </div>

    </div>
</div>

<div class="blog-details-area  pb-100">
    <div class="container">
        <div class="row">
            <div>
                <div class="blog-details-desc">
                    <div class="news-title ">

                        <div class="new-date"></div>
                    </div>
                    &nbsp;

                    <div class="news-video" *ngIf="content?.videoPath!=''">
                        <video controls [src]="photoUrl+content.videoPath" metadata>
                          
                        </video>
                       
                    </div>


                    <div class="mission-area ptb-70" *ngIf="content?.contentPageGalleries?.length>0">
                        <div class="container">
                            <div class="mission-content">
                                <div class="mission-slides">
                                    <owl-carousel-o [options]="missionSlides">
                                        <ng-template carouselSlide *ngFor="let item of content?.contentPageGalleries">
                                            <img [src]="photoUrl+item?.imagePath">
                                        </ng-template>
                                    </owl-carousel-o>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="article-content">
                        <p [innerHTML]="content?.content"></p>

                    </div>
                </div>
            </div>

            <div class="application-button" *ngIf="content?.buttonLink">
                <a href="{{content?.buttonLink}}"><button>{{'CLICK_TO_APPLY'|translate}}</button></a>
            </div>
            

        </div>

        <!-- <div class="article-share ptb-70">

            <span>{{'SHARE'|translate}}</span>
            <ul class="social">
                <a [href]="['https://www.facebook.com/sharer/sharer.php?u='+href]" class="facebook" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                        <g clip-path="url(#clip0_1_688)">
                            <path
                                d="M11.5 0C5.14878 0 0 5.14878 0 11.5C0 16.893 3.71312 21.4185 8.72206 22.6614V15.0144H6.35076V11.5H8.72206V9.98568C8.72206 6.07154 10.4935 4.2573 14.3364 4.2573C15.065 4.2573 16.3222 4.40036 16.8365 4.54296V7.72846C16.5651 7.69994 16.0936 7.68568 15.508 7.68568C13.6224 7.68568 12.8938 8.40006 12.8938 10.2571V11.5H16.6502L16.0048 15.0144H12.8938V22.9158C18.5881 22.2281 23.0005 17.3797 23.0005 11.5C23 5.14878 17.8512 0 11.5 0Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1_688">
                                <rect width="23" height="23" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>


                </a>
                <a [href]="['http://twitter.com/share?url='+href+'&text=Bu%20g%C3%B6nderiyi%20g%C3%B6r']"
                    routerLinkActive="router-link-active" class="twitter" target="_blank">

                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path
                            d="M13.7447 1.42773H16.2748L10.7473 7.74535L17.25 16.3422H12.1584L8.17053 11.1283L3.60746 16.3422H1.07582L6.98808 9.58481L0.75 1.42773H5.97083L9.57555 6.19348L13.7447 1.42773ZM12.8567 14.8278H14.2587L5.20905 2.86258H3.7046L12.8567 14.8278Z"
                            fill="white" />
                    </svg>


                </a>

            </ul>
        </div> -->


    </div>
</div>